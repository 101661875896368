.contact {
  &__header {
  }
  &__body {
    margin: 0 auto;
    padding: 45px 30px 30px 30px;
    width: 100%;
    max-width: 1200px;

    display: flex;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 0 30px 30px 30px;
      flex-direction: column-reverse;
    }
  }
  &__footer {}

  &__map-wrapper {
    height: 400px;
    background-color: gray;
  }

  &__main {
    margin-right: 15px;
    width: 75%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-right: 0;
      width: 100%;
    }
  }
  &__aside {
    margin-left: 15px;
    width: 25%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-left: 0;
      width: 100%;
      transform: translateY(-40px);
    }
  }

  &__title {
    @include fontSize(48);
    @include lineHeight(58, 48);
    font-style: normal;
    font-weight: bold;
    color: $masterBlack500;
  }

  &__form {
    margin-top: 30px;
  }

  &__form-success-message {
    margin: 15px 0 30px;
  }
  &__form-success-message--inner {
    padding: 15px;
    background-color: #45e89061;
    border-radius: 5px;

    span.c1 {
      font-size: 1.2em;
      line-height: 1.2em;
      font-weight: 600;
    }
    span.c2 {}
  }
}

.contact-info {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  &__title {
    margin-bottom: 15px;
    @include fontSize(21);
    @include lineHeight(26, 21);
    font-style: normal;
    font-weight: bold;
    color: $masterBlack500;
  }

  &__group {

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__line {
    display: flex;
  }

  &__line-key {
    margin-right: 10px;
    font-weight: bold;
  }

  &__line-link {
    color: $masterBlack500;
    text-decoration: none;
  }
}
