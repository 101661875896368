.mc-flash-notice {
  &__inner {
    margin: 15px auto 15px auto;
    padding: 15px 20px;
    max-width: 1140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $masterRed500;
    color: #fff;
    border-radius: 5px;

    @include fontSize(14);
    @include lineHeight(16, 14);
    font-style: normal;
    font-weight: 500;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      border-radius: 0;
    }
  }
  &--absolute {
    position: absolute;
    left: 0;
    top: 68px;
    width: 100%;
    z-index: 9;

    .s-flash-notice__inner {
      max-width: calc(940px - 30px);
      padding: 15px;
    }
  }
}
