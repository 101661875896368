.my-orders {

  &__header {
    margin: 0 auto;
    padding: 30px 30px 15px 30px;
    width: 100%;
    max-width: 1200px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
    }
  }

  &__body {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      overflow-x: scroll;
    }
  }

  &__products {
    display: flex;
  }

  &__footer {
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @include fontSize(48);
    @include lineHeight(58, 48);
    font-style: normal;
    font-weight: bold;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(26);
      @include lineHeight(36, 26);
    }
  }
  &__list {
    padding: 0;
    list-style: none;

    li {
      &:not(:last-child) {
        padding-bottom: 4px;
      }
    }
  }
  &__username-title {
    @include fontSize(30);
    @include lineHeight(30, 30);
    font-style: normal;
    font-weight: bold;
  }
  &__company-title {
    @include fontSize(30);
    @include lineHeight(30, 30);
    font-style: normal;
    font-weight: bold;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }

  &__table-wrapper {
    width: 100%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: unset;
    }
  }


  .table {
    display: flex;
    flex-flow: column nowrap;
    font-size: .9rem;
    margin: 0;
    line-height: 1.5;
    border: none;
    flex: 1 1 auto;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
  }

  .th {
    display: none;
    font-weight: 700;
    background-color: #f2f2f2;
  }

  .th > .td {
    white-space: normal;
    justify-content: center;
  }

  .tr {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
  }
  .tr--top {
    background-color: #000 !important;
    color: #fff !important;
  }
  .tr:nth-of-type(even) {
    background-color: #f2f2f2;
  }

  .tr:nth-of-type(odd) {
    background-color: #ffffff;
  }

  .td {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.8em;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0px;
    white-space: nowrap;
    border-bottom: 0 solid #d0d0d0;
    align-items: center;
  }
  .text-left {
    text-align: left;
    justify-content: start !important;
  }
  .text-right {
    text-align: right;
    justify-content: end !important;
  }
  .text-primary {
    color: red;
  }
  .mc-pill {
    padding: 2px 8px;
    border-radius: 4px;
    color: #fff;

    &--order {
      background-color: green;
    }
    &--invoice {
      background-color: #035a9b;
    }
  }
  .mc-icon {
    //width: 24px;
    //height: 24px;
    //background-color: #bebebe;
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      //background-color: #888888;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
