.mc-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  transition: all .2s linear;
  border-radius: 3px;

  &--large {
    padding: 20px 60px;
  }
  &--border {
    border: 1px solid;
  }
  &--border-red {
    color: $masterRed500;
    border-color: $masterRed500;

    &:hover {
      background-color: $masterRed500;
      color: #fff;
    }
  }

  &--submit {
    padding: 20px 60px;
    color: $masterBlack200;
    background-color: $masterBlack50;

    &:hover {
      color: $masterBlack500;
      background-color: $masterBlack200;
    }

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
    }
  }

  &--login {
    padding: 20px 60px;
    width: 100%;
    color: #fff;
    background-color: $masterRed500;

    &:hover {
      color: #fff;
      background-color: $masterRed600;
    }
  }

  &--reset-password {
    padding: 20px 60px;
    width: 100%;
    color: #fff;
    background-color: $masterRed500;

    &:hover {
      color: #fff;
      background-color: $masterRed600;
    }
  }

  &--register {
    padding: 20px 60px;
    width: 100%;
    color: #fff;
    background-color: $masterRed500;


    &:hover {
      color: #fff;
      background-color: $masterRed600;
    }
  }

  &--newslette {
    padding: 15px 60px;
    color: $masterBlack200;
    background-color: $masterBlack50;

    &:hover {
      color: $masterBlack500;
      background-color: $masterBlack200;
    }

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
    }
  }

  &--load-more {
    padding: 15px 60px;
    width: 100%;
    color: $masterBlack200;
    background-color: $masterBlack50;

    &:hover {
      color: $masterBlack500;
      background-color: $masterBlack200;
    }

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
    }
  }

  &--cookie {
    padding: 15px 30px;
    color: #fff;
    background-color: $masterRed500;

    &:hover {
      color: #fff;
      background-color: $masterRed600;
    }

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
    }
  }

  &--cart-download {
    display: block;
    width: 100%;
    padding: 15px;
    border: 1px solid;
    border-color: $masterRed500;
    color: $masterRed500;
    text-transform: capitalize;
    text-align: center;
    text-decoration: none;

    &:hover {
      background-color: $masterRed500;
      color: #fff;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:disabled {
      background-color: #f2f2f2;
      color: #bfbfbf;
      border: 0px solid;
    }

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {}
  }

  &--remove {
    width: 100%;
    padding: 5px 15px;
    @include fontSize(14);
    @include lineHeight(14, 14);
    border: 1px solid;
    border-color: $masterRed500;
    color: $masterRed500;
    text-transform: capitalize;

    &:hover {
      background-color: $masterRed500;
      color: #fff;
    }

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {}
  }

  &--cart-save {
    padding: 7px 30px;
    text-decoration: none;
  }
  &--cart-clear {
    padding: 7px 30px;
    color: #fff;
    background-color: $masterRed500;
    text-decoration: none;
  }

  &--block {
    display: block;
  }

  &--menu-login {
    display: block;
    margin-bottom: 5px;
    padding: 15px;
    width: 100%;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: $masterRed500;
    border-radius: 5px;
    border: 0px;
    cursor: pointer;
  }
  &--menu-register {
    display: block;
    padding: 15px;
    width: 100%;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: $masterRed500;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid $masterRed500;
    cursor: pointer;
  }

  &--error {
    display: block;
    margin-bottom: 5px;
    padding: 15px;
    width: 100%;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: $masterRed500;
    border-radius: 5px;
    border: 0px;
    cursor: pointer;
  }

  &--mt-20 {
    margin-top: 20px;
  }
}
