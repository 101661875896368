// Colors
$masterBlack500: #000000;
$masterBlack400: #4D4D4D;
$masterBlack300: #999999;
$masterBlack200: #BFBFBF;
$masterBlack100: #E6E6E6;
$masterBlack50: #F2F2F2;
$masterRed900: #6C0C0F;
$masterRed800: #850E13;
$masterRed700: #A61217;
$masterRed600: #C7161C;
$masterRed500: #DD181F;
$masterRed400: #E75D62;
$masterRed300: #F1A3A5;
$masterRed200: #F7C5C7;
$masterRed100: #FCE8E9;
$masterRed50: #FDF3F4;

// Fonts
$fontPoppins: 'Poppins', sans-serif;
$fontRaleway: 'Raleway', sans-serif;

// Import fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap&subset=latin-ext");
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
