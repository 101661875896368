.ms-cart {
  margin-bottom: 60px;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
      justify-content: space-between;
    }
  }
  &__body {
    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
    }
  }
  &__table-wrapper {

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      overflow-x: scroll;
    }
  }
  &__footer {}
}
.ms-cart-table {
  width: 100%;

  th {
    padding: 15px 20px 15px 0;
    text-align: left;
    color: #000;
    box-shadow: 0px -1px 0px #ECEEEF;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 5px 0;
    }
  }

  &__tr-title {

  }
  &__tr-title--right {
    text-align: right !important;
  }
}
.ms-cart-item {
  box-shadow: 0px -1px 0px #ECEEEF;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    display: flex;
    align-items: center;
    //flex-direction: column;
  }

  &__title {
    padding: 15px 20px 15px 0;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 0;
    }

    a {
      color: #dd181f;
      text-decoration: none;
    }
  }
  &__quantity {
    padding: 15px 20px;
  }
  &__single-price {
    padding: 15px 20px;
    text-align: right;
  }
  &__total-price {
    padding: 15px 20px;
    text-align: right;
  }
}
