.price-login {
  margin-top: 60px;
  padding: 35px 25px 25px 25px;
  position: relative;
  width: fit-content;
  background-color: $masterBlack50;
  border-radius: 5px;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {
    padding: 35px 15px 15px 15px;
    width: 100%;
  }

  @include media("<=576px") {

  }

  &__icon {
    position: absolute;
    top: -20px;
    left: 25px;
  }
  &__header {}
  &__body {
    padding-top: 20px;
    display: flex;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      flex-direction: column;
    }

    @include media("<=768px") {}

    @include media("<=576px") {

    }
  }

  &__info {
    color: $masterRed500;
  }

  &__button {
    padding: 10px 40px;
    display: block;
    border-radius: 3px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      text-align: center;
    }

    @include media("<=768px") {}

    @include media("<=576px") {

    }
  }

  &__button--full-red {
    margin-right: 25px;
    color: #fff;
    background-color: $masterRed500;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      margin-right: 0;
      margin-bottom: 15px;
    }

    @include media("<=768px") {}

    @include media("<=576px") {

    }

    &:hover {
      background-color: #A61217;
    }
  }
  &__button--border-red {
    color: $masterRed500;
    border: 1px solid $masterRed500;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {}

    &:hover {
      color: #fff;
      background-color: $masterRed500;
    }
  }

  &--main-slider {
    margin-top: 30px;
  }
}
