.s-product-document {
  display: flex;
  align-items: center;
  font-family: $fontRaleway;
  font-weight: bold;
  text-decoration: none;
  width: fit-content;

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  &:hover {
    .s-product-document__icon {
      svg {
        fill: $masterRed500;;
      }
    }
    .s-product-document__text {
      color: $masterRed500;
    }
  }

  &__icon {
    margin-right: 5px;
    display: flex;

    svg {
      fill: $masterBlack400;;
    }
  }
  &__text {
    color: $masterBlack400;
  }
}
