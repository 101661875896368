.mc-product {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  transition: all .2s linear;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    padding: 0;
  }

  &__header {}
  &__comparison-icon {
    position: relative;
    z-index: 60;
    cursor: pointer;

    svg {
      transition: all .3s ease-in-out;
    }

    &:hover {
      svg {
        fill: $masterRed500;
      }
    }
  }
  &__body {
    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 15px;
    }
  }
  &__footer {}

  &__info {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    @include fontSize(18);
    @include lineHeight(27, 18);
    font-style: normal;
    font-weight: bold;
  }
  &__info--discount {
    background-color: $masterRed500;
    color: #fff;
  }

  &__image {
    width: 100%;
  }
  &__brand {
    font-style: normal;
    font-weight: bold;
    @include fontSize(12);
    @include lineHeight(18, 12);
    color: $masterBlack200;
    text-transform: uppercase;
  }
  &__title {
    margin-bottom: 5px;
    font-style: normal;
    font-weight: bold;
    @include fontSize(18);
    @include lineHeight(22, 18);
    color: $masterBlack500;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      min-height: 44px;
    }
  }
  &__short-description {
    margin-top: 0;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    @include fontSize(15);
    @include lineHeight(19, 15);
    color: $masterBlack300;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: none;
    }
  }
  &__description {
    @include fontSize(16);
    @include lineHeight(24, 16);
    color: $masterBlack300;
  }
  &__price-wrapper {}
  &__price-kn {}
  &__price-eu {
    .mc-product__price {
      @include fontSize(18);
      @include lineHeight(18, 18);
    }
    .mc-product__currency {}
  }
  &__price {
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    @include fontSize(21);
    @include lineHeight(31, 21);
    color: $masterRed500;
  }
  &__currency {
    font-style: normal;
    font-weight: bold;
    @include fontSize(14);
    @include lineHeight(21, 14);
    color: $masterBlack200;
    text-transform: uppercase;
  }

  &__link {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 50;
    }
  }

  &--w25 {
    width: 23%;
  }

  &--w33 {
    width: 32%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 48%;
    }
  }

  &--w100 {
    width: 100%;
  }

  &--featured {
    flex-direction: row;
    align-items: center;

    &:hover {
      box-shadow: unset !important;

      .mc-add-to-cart {
        background-color: $masterRed500;
        color: #fff;
      }
    }

    .mc-product__header {
      position: relative;
      width: 35%;
    }
    .mc-product__body {
      padding-left: 30px;
      width: 65%;
    }
    .mc-product__brand {
      @include fontSize(12);
      @include lineHeight(18, 12);
    }
    .mc-product__title {
      @include fontSize(24);
      @include lineHeight(36, 24);
    }
    .mc-product__description {
      margin-top: 0;
      width: 80%;
    }
    .mc-product__price-wrapper {
      margin-bottom: 20px;
    }
  }

  &:hover {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);

    .mc-add-to-cart {
      background-color: $masterRed500;
      color: #fff;
    }

    .mc-add-to-cart:disabled {
      background-color: transparent;
      color: $masterBlack100;
    }
  }

  &--loading {
    .mc-product__image {}
    .mc-post-card__title {
      display: block;
      width: 100%;
      height: 42px;
      background-color: #f3f3f3;
    }
    .price-login-alert__inner {
      display: block;
      width: 100%;
      height: 28px;
      background-color: #f3f3f3;
    }
    .mc-add-to-cart {
      display: block;
      width: 100%;
      height: 44px;
      background-color: #f3f3f3;
    }
  }

  @keyframes hintloading {
    0% { opacity: 0.5; }
    50%  { opacity: 1; }
    100% { opacity: 0.5;}
  }

  @-webkit-keyframes hintloading {
    0% { opacity: 0.5; }
    50%  { opacity: 1; }
    100% { opacity: 0.5;}
  }
}

.mc-product--loading .mc-product__image,
.mc-product--loading .mc-product__title,
.mc-product--loading .price-login-alert__inner,
.mc-product--loading .mc-add-to-cart,
{
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}
