.about-us {
  margin-bottom: 60px;

  &__header {
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    max-width: 825px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
    }
  }
  &__title {
    @include fontSize(48);
    @include lineHeight(58, 48);
    font-style: normal;
    font-weight: bold;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(26);
      @include lineHeight(36, 26);
    }
  }
  &__body {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 825px;

    h2 {
      @include fontSize(28);
      @include lineHeight(42, 28);
      font-style: normal;
      font-weight: bold;
    }

    p {
      @include fontSize(16);
      @include lineHeight(24, 16);
      font-style: normal;
      font-weight: normal;
      color: $masterBlack300;
    }
  }
  &__footer {}
}
