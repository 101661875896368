.mc-quick-search {
  padding: 30px 0;
  background-color: $masterBlack50;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    display: none;
  }

  &__inner {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    padding: 20px 0;
    width: 100%;
    border-radius: 100px;
    text-indent: 45px;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      text-indent: 30px;
    }
  }

  &__icon {
    position: absolute;
    top: 22px;
    right: 60px;
  }
}
