.mc-products-row {
  margin: 30px auto 45px auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    margin: 30px auto 15px auto;
    padding: 0;
  }

  &__header {
    padding: 20px 0;
    border-bottom: 1px solid $masterBlack100;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin: 0 30px;
    }
  }
  &__body {
    margin-top: 20px;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {}
  }
  &__body--flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  &__footer {}

  &__title {
    display: flex;
    flex-direction: column;
    @include fontSize(21);
    @include lineHeight(26, 21);
  }
  &__title-400 {
    font-weight: 400;
  }
  &__title-700 {
    color: $masterRed500;
    font-weight: 700;
    text-transform: uppercase;
  }
}
