.mc-products-quick-search {
  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {}

  &__inner {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
    border-radius: 100px;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 0;
      border-radius: 10px;
    }
  }

  &__input {
    padding: 20px 0;
    width: 100%;
    border-radius: 100px;
    text-indent: 15px;
  }

  &__icon {
    position: absolute;
    top: 22px;
    left: 30px;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      right: 30px;
      left: unset;
    }
  }
}
