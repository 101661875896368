.mc-category-card {
  padding: 25px 20px 10px 20px;
  background-color: #fff;
  border-radius: 10px;
  transition: all .2s linear;
  margin-bottom: 15px;

  &__title {
    width: 80%;
    @include fontSize(18);
    @include lineHeight(22, 18);
    font-style: normal;
    font-weight: bold;
    color: $masterBlack500;
  }

  &__list {
    padding: 0;
    list-style: none;
  }
  &__item {}
  &__item,
  &__link {
    @include fontSize(12);
    @include lineHeight(24, 12);
    font-style: normal;
    font-weight: 600;
    color: $masterBlack200;
    text-decoration: none;
    transition: color .2s linear;
  }
  &__link {
    &:hover {
      color: $masterBlack500;
    }
  }
  &__origin {
    position: relative;
    @include fontSize(12);
    @include lineHeight(24, 12);
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    color: $masterRed500;
    text-decoration: none;
    opacity: 0;
    transition: opacity .2s linear;

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      border-radius: 1px;
      background-color: $masterRed500;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 0.2s cubic-bezier(0.79, 0.01, 0.22, 0.99) 0s, transform-origin 0s step-end
    }

    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }
  }

  &--w25 {
    width: 25%;
  }

  &:hover {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);

    .mc-category-card__origin {
      opacity: 1;
    }
  }
}
