.mc-product-banner {
  margin: 0 auto;
  padding: 55px 110px;
  width: 100%;
  max-width: 1200px;
  height: 554px;
  position: relative;
  display: flex;
  align-items: flex-end;
  border-radius: 10px;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    padding: 30px;
    flex-direction: column;
    border-radius: 0;
  }

  &__title {
    @include fontSize(58);
    @include lineHeight(65, 58);
    color: #fff;
    font-style: normal;
    font-weight: 900;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      @include fontSize(48);
      @include lineHeight(58, 48);
    }
  }

  .mc-product {
    position: absolute;
    top: 60px;
    right: 170px;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      position: relative;
      top: unset;
      right: unset;
      width: 100%;
    }
  }
}
