// font size
@mixin fontSize($sizeValue) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue / 16) + rem;
}

// line height
@mixin lineHeight($heightValue, $fontSize) {
  line-height: ($heightValue / $fontSize) + em;
}


