.mc-post-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  transition: all .2s ease-in-out;

  &__header {}
  &__body {
    margin-bottom: 15px;
    padding: 10px 20px;

    p {
      margin-bottom: 0;
      @include fontSize(16);
      @include lineHeight(24, 16);
      font-style: normal;
      font-weight: normal;
      color: $masterBlack300;
    }
  }
  &__footer {}

  &__image {
    width: 100%;
    height: 205px;
    border-radius: 5px;
  }
  &__title {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    @include fontSize(18);
    @include lineHeight(22, 18);
    color: $masterBlack500;
  }
  &__content {
    margin-bottom: 0;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: normal;
    color: $masterBlack300;
  }
  &__meta-wrapper {
    margin-bottom: 10px;
  }
  &__date {
    @include fontSize(14);
    @include lineHeight(24, 14);
    font-style: normal;
    font-weight: bold;
    color: $masterRed500;
  }

  &__link {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 50;
    }
  }

  &--w33 {
    width: 32%;

    @include media(">992px", "<=1180px") {}

    @include media(">769px", "<=992px") {
      width: 50%;
    }

    @include media(">481px", "<=768px") {
      width: 50%;
    }

    @include media(">=320px", "<=480px") {
      width: 100%;
    }
  }

  &--loading {
    .mc-post-card__image {
      height: 205px;
    }

    .mc-post-card__meta-wrapper {
      display: block;
      width: 80px;
      height: 20px;
      background-color: #f3f3f3;
    }

    .mc-post-card__title {
      display: block;
      width: 100%;
      height: 42px;
      background-color: #f3f3f3;
    }
    .mc-post-card__content {
      display: block;
      width: 100%;
      height: 72px;
      background-color: #f3f3f3;
    }
  }

  &:hover {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);
  }

  @keyframes hintloading {
    0% { opacity: 0.5; }
    50%  { opacity: 1; }
    100% { opacity: 0.5;}
  }

  @-webkit-keyframes hintloading {
    0% { opacity: 0.5; }
    50%  { opacity: 1; }
    100% { opacity: 0.5;}
  }
}

.mc-post-card.mc-post-card--loading .mc-post-card__image,
.mc-post-card.mc-post-card--loading .mc-post-card__meta-wrapper,
.mc-post-card.mc-post-card--loading .mc-post-card__title,
.mc-post-card.mc-post-card--loading .mc-post-card__content,
{
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}
