













































































































@import "~include-media/dist/include-media";

.posts-slider {
  padding: 30px 0;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    padding: 0 30px;
  }

  .swiper-wrapper {
    padding: 0 0 45px 0;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 0 0 60px 0;
    }
  }

  .swiper-slide-active {

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      //margin-left: 30px;
      filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #DD181F;
  }
}
