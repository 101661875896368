.ms-form-group {
  margin-bottom: 20px;

  &__label {
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    color: $masterBlack500;
  }
  &__input,
  &__textarea {
    padding: 12px 15px;
    width: 100%;
    border: 1px solid #F2F2F2;
    border-radius: 4px;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }

  &__input--invalid {
    border: 1px solid $masterRed500;
  }

  &__link {
    color: $masterRed500;
  }

  &__input--checkout {
    padding: unset;
    width: unset;
    border: unset;
    border-radius: unset
  }

  &--checkout {
    .ms-form-group__label {
      @include fontSize(14);
      @include lineHeight(24, 14);
      font-style: normal;
      font-weight: normal;
      color: $masterBlack300;

      a {
        color: #D9534F;
      }
    }
  }

  &--radio {
    margin-bottom: 10px;

    input {
      margin-right: 5px;
    }
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: $masterRed500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }
}
