.mc-main-slider {

  &__inner {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      padding: 0 15px;
      height: unset;
    }

    @include media("<=768px") {}

    @include media("<=576px") {}

  }

  &__slide {
    display: flex;
    align-items: center;
    //padding-left: 90px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      //padding-left: 0px;
    }

    @include media("<=768px") {
      padding-left: 0;
      flex-direction: column-reverse;
    }

    @include media("<=576px") {

    }
  }

  &__meta {
    width: 40%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      width: 100%;
      text-align: center;
    }

    @include media("<=576px") {}
  }
  &__brand {
    font-style: normal;
    font-weight: bold;
    @include fontSize(16);
    @include lineHeight(24, 16);
    text-transform: uppercase;
    color: $masterBlack200;
  }
  &__title {
    margin: 10px 0 10px 0;
    font-style: normal;
    font-weight: 900;
    @include fontSize(32);
    @include lineHeight(36, 32);
    color: $masterBlack500;
  }
  &__description {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    @include fontSize(18);
    @include lineHeight(28, 18);
    color: $masterBlack300;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(16);
      @include lineHeight(24, 16);
    }
  }

  &__price-wrapper {
    margin: 15px 0 20px 0;
  }
  &__price {
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    @include fontSize(32);
    @include lineHeight(32, 32);
    color: $masterRed500;
  }
  &__currency {
    font-style: normal;
    font-weight: bold;
    @include fontSize(14);
    @include lineHeight(21, 14);
    color: $masterBlack200;
    text-transform: uppercase;
  }

  &__image-wrapper {
    width: 50%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      width: 100%;
      height: unset;
    }

    @include media("<=576px") {}
  }
  &__image {

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
    }
  }

  .swiper-slide {
    padding-bottom: 30px;
  }

  .swiper-pagination {
    position: relative;
    width: 100%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      flex-direction: row;
      justify-content: center;
    }

    @include media("<=576px") {}

  }

  .swiper-slide-active {

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      //margin-left: 30px;
      filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
    }

    @include media("<=576px") {}

  }

  .swiper-pagination-bullet {
    margin: 4px 4px 45px 4px !important;
    width: 18px;
    height: 18px;
    opacity: 0.1;

    :not(:last-child) {}
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #DD181F;
  }
}
