.mc-post {

  &__header {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;
  }

  &__featured-image-wrapper {}
  &__featured-image {
    width: 100%;
  }

  &__body {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 880px;
  }

  &__meta {
    padding: 20px 0;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      padding: 20px 0 10px 0;
    }

    @include media("<=576px") {}
  }

  &__date {
    color: $masterRed500;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
  }

  &__title {
    color: $masterBlack500;
    @include fontSize(48);
    @include lineHeight(58, 48);
    font-style: normal;
    font-weight: bold;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      @include fontSize(28);
      @include lineHeight(28, 28);
    }

    @include media("<=576px") {}
  }

  &__content {

    h1 {
      @include fontSize(48);
      @include lineHeight(58, 48);
      font-style: normal;
      font-weight: bold;
    }

    h2 {
      @include fontSize(40);
      @include lineHeight(50, 40);
      font-style: normal;
      font-weight: bold;
    }

    h3 {
      @include fontSize(32);
      @include lineHeight(42, 32);
      font-style: normal;
      font-weight: bold;
    }

    h4 {
      @include fontSize(24);
      @include lineHeight(34, 24);
      font-style: normal;
      font-weight: bold;
    }

    h5 {
      @include fontSize(16);
      @include lineHeight(25, 16);
      font-style: normal;
      font-weight: bold;
    }

    h6 {
      @include fontSize(12);
      @include lineHeight(22, 12);
      font-style: normal;
      font-weight: bold;
    }

    p {
      color: $masterBlack300;
      @include fontSize(16);
      @include lineHeight(26, 16);
      font-style: normal;
      font-weight: normal;
    }

    a {
      color: $masterRed500;
      font-weight: 600;
    }

    ul {
      li {
        color: $masterBlack300;
        @include fontSize(16);
        @include lineHeight(26, 16);
        font-style: normal;
        font-weight: normal;
      }
    }

    ol {
      li {
        color: $masterBlack300;
        @include fontSize(16);
        @include lineHeight(26, 16);
        font-style: normal;
        font-weight: normal;
      }
    }
  }
  &__footer {}
}
