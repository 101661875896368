.mc-categories {
  margin: 0 auto;
  padding: 0 30px 0 30px;
  width: 100%;
  max-width: 1200px;
  position: relative;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    display: none;
  }

  &__row {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    gap: 30px;
  }

  &--product-filter {
    padding: 0 0 1px 0;
  }

  &__row--wrap {
    flex-wrap: wrap;
  }
  &__row--gap-0 {
    gap: 0;
  }
}
