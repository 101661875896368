.mc-header {
  padding: 25px 0;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    padding: 15px 0;
  }

  &__inner {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-wrapper {
    position: relative;
    width: 160px;
    z-index: 999999;
  }
  &__logo {}
  &__homepage-link {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: transparent;
  }
}

.mc-mobile-ham {
  display: none;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 18px;
    width: 24px;
  }

  @include media("<=768px") {}

  @include media("<=576px") {}

  &__line {
    display: block;
    width: 24px;
    height: 3px;
    background-color: $masterBlack500;
    border-radius: 1px;
  }
  &__line--top {}
  &__line--middle {}
  &__line--bottom {}
}

.main-menu-show {
  .mc-mobile-ham {}
  .main-menu {
    display: flex;
    flex-direction: column;
    transform: translateX(0);
  }
}
