.mc-footer {
  padding: 60px 0;
  background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%);

  &__inner {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;
  }

  &__body {
    display: flex;

    @include media(">992px", "<=1180px") {}

    @include media(">769px", "<=992px") {}

    @include media(">481px", "<=768px") {}

    @include media(">=320px", "<=480px") {
      flex-direction: column;
    }
  }

  &__col {
    width: 25%;

    @include media(">992px", "<=1180px") {}

    @include media(">769px", "<=992px") {}

    @include media(">481px", "<=768px") {}

    @include media(">=320px", "<=480px") {
      width: 100%;
      text-align: center;
    }
  }

  &__logo {

  }

  &__social-icons {
    display: flex;
    list-style: none;
    margin-left: 35px;
    padding: 0;

    @include media(">992px", "<=1180px") {}

    @include media(">769px", "<=992px") {}

    @include media(">481px", "<=768px") {}

    @include media(">=320px", "<=480px") {
      margin-left: unset;
      padding: 0;
      align-items: center;
      justify-content: center;
    }
  }

  &__social-icon-item {
    margin: 0 5px;
    width: 22px;
    height: 22px;

    img {
      width: 100%;
    }
  }
  &__social-icon-link {}

  &__title {
    margin: 0;
    @include fontSize(21);
    @include lineHeight(26, 21);
    color: $masterBlack500;
    font-weight: 700;
  }
  &__title-pt-30 {
    padding-top: 30px;
  }

  &__list {
    padding: 0;
    list-style: none;
  }

  &__list-text-bold {
    margin-right: 10px;
    color: $masterBlack300;
    font-weight: bold;
    font-style: normal;
  }

  &__list-item {
    margin-bottom: 7px;
    @include fontSize(14);
    @include lineHeight(21, 14);
  }
  &__list-item--no-padding {
    margin-bottom: 0;
  }
  &__list-link {
    @include fontSize(14);
    @include lineHeight(21, 14);
    color: $masterBlack300;
    text-decoration: none;
    transition: all .2s linear;

    &:hover {
      color: $masterBlack500;
    }
  }
  &__newsletter-wrapper {
    margin: 15px 0;
  }
  &__footer {
    margin-top: 30px;
    color: $masterBlack300;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      text-align: center;
    }
  }

  &__author {
    margin-top: 24px;
    @include fontSize(14);
    @include lineHeight(21, 14);
    color: $masterBlack300;

    a {
      text-decoration: none;
      color: $masterRed500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
