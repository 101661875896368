.products-filter {
  background-color: #fff;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  &__header {
    padding: 10px 45px;
    display: flex;
    align-items: center;
  }
  &__body {
    padding: 0 45px;
  }
  &__footer {}

  &__title {
    margin-left: 45px;
    @include fontSize(28);
    @include lineHeight(58, 28);
    color: $masterBlack500;
    font-style: normal;
    font-weight: bold;
  }
  &__arrow {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 53px;
    background-color: $masterBlack50;
    border-radius: 50%;
  }

  &__search {
    margin: 20px 0;
  }
  &__search-input {
    padding: 24px 45px;
    @include fontSize(21);
    @include lineHeight(26, 21);
    font-style: normal;
    font-weight: bold;
    color: $masterBlack500;
    background-color: #fff;
    width: 100%;
    border-radius: 100px;
    filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
  }
  &__categories {}
}

.filter {
  &__dis {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.70);
  }
  &__header {
    padding: 0;
    border-bottom: 1px solid #EEEEEE;
  }
  &__body {
    padding: 15px 0 45px 0;
  }
  &__title {
    margin-bottom: 20px;
  }
  &__form-group {
    position: relative;
    padding: 5px 0;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    @include fontSize(14);
    @include lineHeight(21, 14);
    color: #C9C9C9;

    input[type=checkbox]:checked {
      background-color: $masterRed500;
      color: $masterRed500;
    }

    input[type=checkbox]:checked + label {
      color: $masterRed500;
      text-decoration: line-through;
    }
  }
  &__form-group-checkbox {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
  &__form-group-checkbox-label {}

  &--checkbox {}

  .checkbox {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 10px;
    @include fontSize(14);
    @include lineHeight(21, 14);
    color: var(--color);
    cursor: pointer;

    &--disabled {
      color: var(--disabled);
    }

    &:hover {
      .checkbox__label {
        color: $masterRed500;
      }
    }
  }

  .checkbox__control {
    display: inline-grid;
    width: 18px;
    height: 18px;
    border: 1px solid #C9C9C9;
    border-radius: 3px;

    svg {
      position: relative;
      top: 2px;
      left: 2px;
      transition: transform 0.1s ease-in 25ms;
      transform: scale(0);
      transform-origin: center center;
      width: 12px;
      height: 12px;
    }
  }

  .checkbox__input {
    display: grid;
    grid-template-areas: "checkbox";
    pointer-events: none;

    > * {
      grid-area: checkbox;
    }

    input {
      opacity: 0;
      width: 18px;
      height: 18px;

      &:focus + .checkbox__control {
        //box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
      }

      &:checked + .checkbox__control {
        background-color: $masterRed500;
        border: 1px solid $masterRed500;
      }

      &:checked + .checkbox__control svg {
        transform: scale(1);
      }

      &:checked + .checkbox__label {
        color: $masterRed500;
      }

      &:disabled + .checkbox__control {
        color: var(--disabled);
      }
    }
  }

  .checkbox__label {
    @include fontSize(14);
    @include lineHeight(21, 14);
    color: #C9C9C9;
  }

}

.category-filter {
  &__header {
    padding: 0;
  }
  &__body {
    padding: 5px 0 30px 0;
    display: flex;
    flex-direction: column;
  }
  &__title {
    @include fontSize(18);
    @include lineHeight(22, 18);
    font-style: normal;
    font-weight: bold;
    color: $masterBlack500;

    a {
      color: $masterBlack500;
      text-decoration: none;

      &:hover {
        color: $masterRed500;
      }
    }
  }
  &__link {
    @include fontSize(12);
    @include lineHeight(24, 12);
    font-style: normal;
    font-weight: 600;
    color: $masterBlack200;
    text-decoration: none;
    transition: color .2s linear;
  }
  &__link {
    &:hover {
      color: $masterBlack500;
    }
  }

  //
  &--placeholder {}
  &__header--placeholder {}
  &__title--placeholder {
    height: 20px;
    width: 200px;
    background-color: #e7e7e7;
    border-radius: 3px;
  }
  &__body--placeholder {}
  &__link--placeholder-1 {
    margin: 4px 0;
    height: 12px;
    width: 160px;
    background-color: #ececec;
    border-radius: 2px;
  }
  &__link--placeholder-2 {
    margin: 4px 0;
    height: 12px;
    width: 140px;
    background-color: #ececec;
    border-radius: 2px;
  }
  &__link--placeholder-3 {
    margin: 4px 0;
    height: 12px;
    width: 130px;
    background-color: #ececec;
    border-radius: 2px;
  }
  &__link--placeholder-4 {
    margin: 4px 0;
    height: 12px;
    width: 115px;
    background-color: #ececec;
    border-radius: 2px;
  }
  &__link--placeholder-5 {
    margin: 4px 0;
    height: 12px;
    width: 100px;
    background-color: #ececec;
    border-radius: 2px;
  }
}
