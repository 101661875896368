.price-login-alert {

  &__inner {
    display: flex;
    align-items: center;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      justify-content: center;
    }

    @include media("<=576px") {}
  }
  &__icon {
    position: relative;
    bottom: 1px;
    margin-right: 5px;
    width: 14px;
    height: 14px;
  }
  &__text {
    font-style: normal;
    font-weight: normal;
    @include fontSize(12);
    @include lineHeight(14, 12);
    color: $masterRed500;
  }
  &--main-slider {
    padding: 15px 0;
  }
  &--mc-product {
    padding: 0;
  }
}
