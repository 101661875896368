
















































































































































@import "~include-media/dist/include-media";
@import "../../../../styles/helpers/_variables";
@import "../../../../styles/helpers/mixins";

.mc-products-quick-search {
  position: relative;
  text-align: center;

  #autosuggest {
    width: 100%;
  }

  #autosuggest__input {
    outline: none;
    position: relative;
    width: 100%;
    border: 0;
    font-size: 21px;
    line-height: 36px;
    border-radius: 100px;
    color: #000000;
    background-color: #fff;
    font-weight: 600;
  }

  .autosuggest__label {
    visibility: hidden;
  }

  #autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .autosuggest__input--open {
    border-radius: 35px 35px 0 0 !important;
  }

  .autosuggest {

    &__results-container {
      position: relative;
      width: 100%;
    }

    &__results {
      margin: 0 auto;
      position: absolute;
      z-index: 10000001;
      width: calc(100%);
      box-sizing: border-box;
      background: #fff;
      text-transform: uppercase;
      color: #000;
      top: 0;
      filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: auto;
      }

    }

    &__results-item-wrapper {
      display: flex;
      text-align: left;
      flex-direction: column;
    }

    &__results-item-name {}
    &__results-item-price {
      color: $masterRed500;
      font-style: normal;
      font-weight: bold;
    }
    &__results-item-price-currency {
      color: $masterBlack200;
      font-style: normal;
      font-weight: bold;
      @include fontSize(14);
      @include lineHeight(21, 14);
    }
    &__results-item-description {
      @include fontSize(14);
      @include lineHeight(19, 14);
    }

    &__results-item {
      padding: 15px 45px;
      @include fontSize(18);
      @include lineHeight(23, 18);
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      color: #000000;
      cursor: pointer;

      @media (max-width: 575px) {
        padding: 15px 30px;
      }

      &:active,
      &:hover,
      &:focus { background-color: #F2F2F2; }
      &--highlighted {}
    }

    &__results-item-icon {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      visibility: hidden;
    }

    &__results-item-icon-item-icon {
      &--villa { background-color: rgba(10, 187, 135, 0.1); }
      &--location { background-color: rgba(10, 187, 135, 0.1); }
    }

    &__results-before {
      padding: 15px 45px;
      text-align: left;
      @include fontSize(21);
      @include lineHeight(26, 21);
      font-style: normal;
      font-weight: bold;
      color: #DD181F;
    }
  }

  #autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
  }
}
