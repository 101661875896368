.mc-news-row {
  margin: 30px auto 90px auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    margin: 30px auto 15px auto;
    padding: 0;
  }

  &__header {
    padding: 20px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid $masterBlack100;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin: 0 30px;
    }
  }
  &__body {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 0 30px;
      flex-direction: column;
    }
  }
  &__body--slider {
    margin-top: 20px;
    display: unset;
    justify-content: unset;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      flex-direction: unset;
    }
  }
  &__body--flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  &__footer {}

  &__title {
    display: flex;
    flex-direction: column;
    @include fontSize(21);
    @include lineHeight(26, 21);
  }
  &__title-400 {
    font-weight: 400;
  }
  &__title-700 {
    color: $masterRed500;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__origin {
    position: relative;
    color: $masterRed500;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background-color: $masterRed500;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 0.2s cubic-bezier(0.79, 0.01, 0.22, 0.99) 0s, transform-origin 0s step-end
    }

    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }
  }
}
