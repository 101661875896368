.pagination {
  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  &__item {
    width: 40px;
    height: 40px;
    color: $masterBlack500;
    background: $masterBlack50;
    border-radius: 50%;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;

    &:not(:last-child) {
      margin-left: 14px;
    }
  }
  &__link {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $masterBlack500;
    text-decoration: none;
    border-radius: 50%;

    &:hover {
      color: #fff;
      background: $masterRed500;
    }
  }
  &__link--active {
    color: #fff;
    background: $masterRed500;
  }
  &__btn {}
  &__btn--prev {}
  &__btn--next {}
}
