.mc-brands-row {
  margin: 30px auto 90px auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;

  &__header {
    padding: 20px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid $masterBlack100;
  }
  &__body {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
  }
  &__footer {}

  &__title {
    display: flex;
    flex-direction: column;
    @include fontSize(21);
    @include lineHeight(26, 21);
  }
  &__title-400 {
    font-weight: 400;
  }
  &__title-700 {
    color: $masterRed500;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__item {

  }

  &__brand-img {

  }
}
