.page {
  margin-bottom: 60px;

  &__header {
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    max-width: 825px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
    }
  }
  &__header--text-center {
    text-align: center;

    .page__title {
      @include fontSize(136);
      @include lineHeight(58, 136);
      color: #eeeeee;
    }
  }
  &__title {
    @include fontSize(48);
    @include lineHeight(58, 48);
    font-style: normal;
    font-weight: bold;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(26);
      @include lineHeight(36, 26);
    }
  }
  &__subtitle {
    @include fontSize(32);
    @include lineHeight(42, 32);
    font-style: normal;
    font-weight: bold;
  }
  &__body {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 825px;

    h1 {
      @include fontSize(48);
      @include lineHeight(58, 48);
      font-style: normal;
      font-weight: bold;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        @include fontSize(28);
        @include lineHeight(38, 28);
      }
    }

    h2 {
      @include fontSize(40);
      @include lineHeight(50, 40);
      font-style: normal;
      font-weight: bold;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        @include fontSize(20);
        @include lineHeight(24, 20);
      }
    }

    h3 {
      @include fontSize(32);
      @include lineHeight(42, 32);
      font-style: normal;
      font-weight: bold;
    }

    h4 {
      @include fontSize(24);
      @include lineHeight(34, 24);
      font-style: normal;
      font-weight: bold;
    }

    h5 {
      @include fontSize(16);
      @include lineHeight(25, 16);
      font-style: normal;
      font-weight: bold;
    }

    h6 {
      @include fontSize(12);
      @include lineHeight(22, 12);
      font-style: normal;
      font-weight: bold;
    }

    p {
      @include fontSize(16);
      @include lineHeight(24, 16);
      font-style: normal;
      font-weight: normal;
      color: $masterBlack300;
    }

    ul {
      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        padding: 0 0 0 20px;
      }

      li {
        @include fontSize(16);
        @include lineHeight(24, 16);
        font-style: normal;
        font-weight: normal;
        color: $masterBlack300;
      }
    }
  }
  &__footer {}

  &--order-details {
    .page__header { max-width: 1200px; }
    .page__body { max-width: 1200px; }
    .page__footer { max-width: 1200px; }
  }

  &--error-404 {

  }
}
