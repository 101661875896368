// Reset
@import "~normalize.css";

// Helpers
@import "~include-media/dist/include-media";
@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/global";

// Pages
@import "pages/page.page";
@import "pages/homepage.page";
@import "pages/products.page";
@import "pages/about-us.page";
@import "pages/news.page";
@import "pages/contact.page";
@import "pages/cart.page";
@import "pages/login.page";
@import "pages/reset-password.page";
@import "pages/register.page";
@import "pages/my-orders.page";

// Components
@import "components/header.component";
@import "components/footer.component";
@import "components/main-menu.component";
@import "components/user-menu.component";
@import "components/main-slider.component";
@import "components/quick-search.component";
@import "components/products-quick-search.component";
@import "components/product-banner.component";
@import "components/product.component";
@import "components/cart.component";
@import "components/add-to-cart.component";
@import "components/products-row.component";
@import "components/news-row.component";
@import "components/categories.component";
@import "components/category-card.component";
@import "components/post-card.component";
@import "components/brands-row.component";
@import "components/post.component";
@import "components/breadcrumbs.component";
@import "components/button.component";
@import "components/quick-login.component";
@import "components/products-filter.component";
@import "components/pagination.component";
@import "components/s-product.component";
@import "components/discount-label.component";
@import "components/form-group.component";
@import "components/price-login.component";
@import "components/price-login-alert.component";
@import "components/alert.component";
@import "components/s-product-document.component";
@import "components/flash-notice.component";
@import "components/delivery.component";
@import "components/comparison-modal.component";



.quantity {
  position: relative;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]
{
  -moz-appearance: textfield;
}

.quantity input {
  width: 62px;
  height: 48px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 1px solid #eee;
}
.quantity-cart input {
  width: 56px;
  height: 38px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 14px;
  border: 1px solid #eee;
}

.quantity input:focus {
  outline: 0;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 48px;
}
.quantity-cart .quantity-nav {
  float: left;
  position: relative;
  height: 38px;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  border-left: 1px solid #eee;
  width: 20px;
  text-align: center;
  color: #333;
  font-size: 13px;
  line-height: 1.7;
  font-weight: 500;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 0;
  border-bottom: 1px solid #eee;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: -1px;
  height: 50%;
}



//
.add-to-cart {
  padding: 12px 46px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #DD181F;
  border: 0;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &--small {}
  &--medium {}
  &--large {}

  &:hover {
    background-color: #A61217;
  }
}