.quick-login {
  position: relative;

  &__header {
    cursor: pointer;

    &:hover {
      .quick-login__text {
        color: $masterRed500;
      }
      .quick-login__link-icon {
        svg {
          fill: $masterRed500;
        }
      }
    }
  }

  &__text {
    color: $masterBlack500;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  }

  &__link-icon {
    svg {
      fill: $masterBlack500;
      transition: fill 0.2s ease-in-out;
    }
  }

  &__body {
    padding: 15px;
    position: absolute;
    top: 45px;
    right: 0;
    width: 330px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
    z-index: 800;

    p {
      margin: 0;
      @include fontSize(14);
      @include lineHeight(20, 14);
      color: $masterBlack400;
      font-style: normal;
      font-weight: normal;

      a {
        color: $masterRed500;
      }
    }
  }

  &__form-group {
    margin-bottom: 15px;
  }
  &__form-label {
    margin-bottom: 10px;
    display: block;
    @include fontSize(16);
    @include lineHeight(24, 16);
    color: $masterBlack500;
    font-style: normal;
    font-weight: bold;
  }
  &__form-label-link {
    @include fontSize(12);
    @include lineHeight(14, 12);
    color: $masterRed500;
    font-weight: 400;
    text-decoration: none;
  }
  &__form-input {
    padding: 13px 15px;
    width: 100%;
    border: 1px solid #F2F2F2;
    border-radius: 4px;
  }

  &__button {
    margin-top: 10px;
    display: block;
    padding: 15px;
    width: 100%;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: $masterRed500;
    border-radius: 5px;
    border: 0px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #a81013;
    }
  }

  &__button2 {
    margin-top: 5px;
    display: block;
    padding: 15px;
    width: 100%;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: $masterRed500;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid $masterRed500;
    border-color: $masterRed500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $masterRed500;
      color: #fff;
    }
  }

  &__register {
    margin-top: 15px;
    display: block;
    color: $masterRed500;
    font-weight: 400;
  }
  &__link {
    color: $masterRed500;
    font-weight: bold;
  }
}
