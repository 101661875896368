





















































































































































































































.products-slider {
  &__inner {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1200px;
  }

  &__header {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-family: 'Playfair Display', serif;
    font-size: 46px;
    line-height: 54px;
    text-align: center;
    color: #668ca4;
  }
  &__subtitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #50504f;
  }
  &__body {
    padding: 30px 0;
    display: flex;
    gap: 20px;
  }
  &__footer {
  }
}
