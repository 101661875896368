.comparison-modal {
  position: absolute;
  top: 103px;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-top: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  z-index: 9999;
  transform: translateX(100%);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1),
  -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);

  &--show {
    transform: translateX(0);
  }

  &__inner {
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    max-width: 1200px;
  }

  &__body {
    display: flex;
    width: fit-content;
    overflow: auto;
    gap: 15px;

    .mc-product {
      width: 284px;
    }
  }

}

