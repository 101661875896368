.mc-add-to-cart {
  display: block;
  position: relative;
  padding: 15px 40px;
  width: 100%;
  border: unset;
  background-color: transparent;
  @include fontSize(16);
  @include lineHeight(24, 16);
  font-style: normal;
  font-weight: bold;
  color: $masterBlack100;
  cursor: pointer;
  z-index: 60;
  transition: all .2s linear;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    margin: 0 auto;
    text-align: center;
    display: block;
    padding: 10px 40px;
    @include fontSize(14);
    @include lineHeight(22, 14);
  }

  &--red {
    background-color: $masterRed500;
    color: #fff;
  }

  &--border {
    border: 1px solid $masterRed500;
    color: $masterRed500;
  }

  &--w216 {
    width: 216px;
  }

  &:hover {
    background-color: $masterRed500;
    color: #fff;
  }

  &:hover:disabled {

  }

  &:disabled {
    background-color: transparent;
  }
}
