.ms-discount-label {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $masterRed500;
  border-radius: 50%;

  &__text {
    @include fontSize(28);
    @include lineHeight(42, 28);
    font-family: $fontPoppins;
    font-style: normal;
    font-weight: bold;
    color: #fff;
  }

  &--single-product {
    width: 90px;
    height: 90px;
  }

  &--top { top: 0; }
  &--right { right: 0; }

  &--price{
    position: relative;
    width: 60px;
    height: 60px;
    left: 10px;
    bottom: 20px;

    .ms-discount-label__text {
      @include fontSize(18);
      @include lineHeight(32, 18);
    }
  }
}
