.cart {
  margin-bottom: 60px;

  &__header {
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    max-width: 1200px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
    }
  }
  &__header--flex { display: flex;}
  &__header--space-between { justify-content: space-between;}
  &__header--center { align-items: center;}
  &__body {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0;
      flex-direction: column-reverse;
    }
  }
  &__footer {}

  &__title {
    @include fontSize(48);
    @include lineHeight(58, 48);
    font-style: normal;
    font-weight: bold;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(26);
      @include lineHeight(36, 26);
    }
  }

  &__main {
    padding-right: 15px;
    width: 75%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      //padding: 0 30px;
      width: 100%;
    }
  }
  &__aside {
    width: 25%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding-right: unset;
      width: 100%;
    }
  }

  .back-to-products {
    color: $masterRed500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__empty-msg {
    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
    }
  }
}
