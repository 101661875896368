.ms-alert {
  padding: 20px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    padding: 30px;
  }

  &__title {
    @include fontSize(21);
    @include lineHeight(26, 21);
    font-style: normal;
    font-weight: bold;
    color: #D9534F;
  }
  &__content {
    @include fontSize(16);
    @include lineHeight(24, 16);
    color: #6D6E71;
  }

  &__text-block {
    margin: 30px 0 5px 0;
    display: block;
    @include fontSize(16);
    @include lineHeight(24, 16);
    color: $masterRed500;
    font-weight: bold;
  }

  input {
    margin-bottom: 10px;
    padding: 12px 15px;
    width: 100%;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
  }
}
