.products {
  &__header {
    margin: 0 auto;
    padding: 15px 30px;
    width: 100%;
    max-width: 1200px;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 15px;
    }
  }
  &__body {
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    max-width: 1200px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 30px 15px 15px 15px;
    }
  }

  &__aside {
    margin-right: 15px;
    width: 20%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
      display: none;
    }
  }

  &__aside-show {
    display: none;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 88;
      padding: 15px;
    }
  }

  &__aside-top {
    display: none;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
    }
  }

  &__main {
    width: 78%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
    }
  }

  &__title {
    @include fontSize(18);
    @include lineHeight(22, 18);
    font-style: normal;
    font-weight: bold;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
      @include fontSize(22);
      @include lineHeight(32, 22);
      text-align: center;
    }
  }

  &__top {
    padding-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      display: block;
    }
  }

  &__top-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-top: 30px;
    }
  }

  &__filter-toggle {
    display: none;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      display: block;
      box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
      border-radius: 100px;
    }
  }
  &__filter-toggle-inner {
    padding: 0 30px;
  }
  &__filter-toggle-title {
    margin-right: 10px;
    font-family: $fontPoppins;
    font-style: normal;
    font-weight: bold;
    color: $masterBlack500;
    @include fontSize(18);
    @include lineHeight(58, 18);
  }
  &__filter-toggle-icon {
    position: relative;
    top: 4px;
  }

  &__sort-toggle {
    position: relative;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
      border-radius: 100px;
    }
  }
  &__sort-toggle-inner {
    padding: 0 30px;
    cursor: pointer;
  }
  &__sort-toggle-title {
    margin-right: 10px;
    font-family: $fontPoppins;
    font-style: normal;
    font-weight: bold;
    color: $masterBlack500;
    @include fontSize(18);
    @include lineHeight(18, 18);

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      @include lineHeight(58, 18);
    }

  }
  &__sort-toggle-icon {
    position: relative;
    top: 3px;
  }
  &__sort-toggle-icon.v {
    svg {
      transform: rotateX(180deg);
    }
  }
  &__sort-toggle-icon.n {
    svg {
      transform: rotateX(360deg);
    }
  }
  &__sort-toggle-dropdown {
    padding: 15px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 80;
    width: 224px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        padding: 3px 0;
        @include fontSize(14);
        @include lineHeight(14, 14);
        cursor: pointer;
      }
    }
  }

  &__items {
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .mc-product {
      margin-bottom: 30px;
    }

    svg.loader {
      width: 100px;
      height: 100px;
      margin: 20px;
      display:inline-block;
    }
  }

  &__bottom {
    margin-top: 30px;
    margin-bottom: 120px;
    text-align: center;
  }
}

.loader {
  width: 100%;
  text-align: center;
}
