.mc-delivery {
  margin-bottom: 60px;
  //padding: 0 30px;

  &__header {
    padding: 0 0 30px 0;
  }
  &__body {}
  &__footer {}

  &__title {
    @include fontSize(48);
    @include lineHeight(58, 48);
    font-style: normal;
    font-weight: bold;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(24);
      @include lineHeight(34, 24);
    }
  }
}
