.news {
  &__header {
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    max-width: 1200px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
    }
  }
  &__title {
    @include fontSize(48);
    @include lineHeight(58, 48);
    font-style: normal;
    font-weight: bold;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(26);
      @include lineHeight(36, 26);
    }
  }
  &__body {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    article {
      margin-bottom: 45px;
    }
  }

  &__footer {
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    max-width: 1200px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
